<template>
<b-dropdown ref="question-select-dropdown" class="btn-select-dropdown w-100" variant="primary" no-flip toggle-class="text-decoration-none">
      <template v-slot:button-content>
            <span v-if="selected" class="d-inline-block text-truncate w-100 align-bottom" style="max-width:250px">                  
                 {{selectedindex + 1}}. {{selected.question}}                  
            </span>
            <span v-else >
                 Alla frågor
            </span>
      </template>
      <div v-if="!loading" class="btn-select">
            <b-row v-if="showclear && id" class="align-items-center clear-select-row" @click="SelectQuestion(false)">
                  <b-col class="text-center p-3 bg-danger text-white">
                        Rensa val
                  </b-col>
            </b-row>
            <b-row @click="SelectQuestion(question)" v-for="(question, index) in questions" :key="question.id" class="align-items-center btn-select-row" :class="{ 'btn-select-selected': isSelected(question)}">
                  <b-col cols="auto">                        
                        {{index +1 }}. {{question.question}}
                  </b-col>
            </b-row>
      </div>
      <b-spinner variant="primary" v-else></b-spinner>
</b-dropdown>
</template>

<script>
export default {
      name: "QuestionSelect",
      props: {"id": String, showclear: Boolean},
      data() {
            return {
                  selectedindex: 0
            };
      },
      computed: {
            loading() {
                  return this.$store.getters["tipsg/questionsloading"];
            },
            questions() {                 
                  var data = this.$store.getters["tipsg/questions"];
                  if (data) {
                        return data.questions;
                  }
                  return [];
            },            
            selected() {
                  var q = false;
                  if(this.id){
                        for(var i = 0; i < this.questions.length;i++)
                        {
                              if(this.questions[i].id == this.id){
                                    this.selectedindex = i;
                                    return this.questions[i];
                              }
                        }
                  }
                  return q;
            }
      },
      methods: {  
            isSelected(q){
                  if(this.selected && this.selected.id == q.id)
                        return true;
                  return false;
            },        
            SelectQuestion(question)
            {
                  this.$emit("select", question);
                  this.$refs["question-select-dropdown"].hide();
            }
      }
};
</script>
